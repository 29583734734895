<template>
    <div class='w-full'>
        <div class="p-4">
            <sub-header
            ></sub-header>
        </div>
        <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">
        <vue-good-table
        :isLoading="loadingTenants"
          :columns="computedColumns" 
          :rows="rowsData"
          :fixed-header="false"
          :pagination-options="{ enabled: true, mode: 'records', dropdownAllowAll: false }"
          @on-column-filter="onColumnFilter" 
          v-bind="config" 
          :totalRows="totalRows"
          @on-per-page-change="onPageChange($event, true)" 
          @on-page-change="onPageChange($event)"
          @on-selected-rows-change="selectionChanged"
          
        >
        <template #column-filter="{ column, updateFilters }">
                  <div v-if="column.filterOptions && column.filterOptions.enabled && column.config && column.config.filter_type === 'date'">
                      <DatePicker range v-model="date_range" @input="(value) => updateColumnFilters(column, value, updateFilters)" 
                          :key="date_range_key" class="w-full" format="DD MMM, YYYY" value-type="timestamp"  :placeholder="due_date" />
                      </div>
                  <dropdown
                      :ref="setFilterRef"
                      reposition
                      searchable
                      class="filter-dropdown"
                      placeholder="Select"
                      v-else-if="column.filterOptions && column.filterOptions.enabled && column.config && !['indicators', 'allocation'].includes(column.config.type)"
                      :options="column.filterOptions.filterDropdownItems"
                      @input="(value) => updateColumnFilters(column, value, updateFilters)"
                      :value="getFilterValue(column)"
                      @search="searchFilter(column, $event)"
                      :limit="1"
                      maxWidth="100%"
                      width="100px"
                      minWidth="100%"
                      :config="{ label: 'name', trackBy: 'id' }"
                      :multiple="false"
                      :taggable="false"
                  >
                      <template #caret>
                          <sort-icon class="fill-current" />
                      </template>
                  </dropdown>
                  <div class="" v-else>
                      <!-- // adding a blank div to remove the default template   -->
                  </div>
                
              </template>
          <template slot="table-row" slot-scope="props">
            <div
              v-if="props.column.field == 'tenant_name'"
              class="flex justify-center"
            >
              {{ props.row.tenant_name == '' ||  props.row.tenant_name == null ? '-' : props.row.tenant_name}}
            </div>
            <div
              v-if="props.column.field == 'requested_domain_name'"
              class="flex justify-center"
            >
              {{ props.row.requested_domain_name == '' ||  props.row.requested_domain_name == null ? '-' : props.row.requested_domain_name }}
            </div>
            <div
              v-if="props.column.field == 'status'"
              class="flex justify-center"
            >
              {{ props.row.status }}
            </div>
            <div
              v-if="props.column.field == 'requested_at'"
              class="flex justify-center"
            >
              {{ props.row.requested_at }}
            </div>
          </template>
        </vue-good-table>
        <button 
          v-if="isFilters"
            class="p-3 cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute  left-2 z-50" :class="isSelectionChanged ? 'top-14' : 'top-2' " @click="clearFilters">
            Clear filters
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import SubHeader from "@/components/SubHeader";
  import { getEmailDomainTenantsList } from "./services";
  import dropdown from "@shared/components/dropdown-base";
  import { onlyUnique } from "@shared/plugins/utils.js";
  import { uuid } from "vue-uuid";
  import { DateTime } from "luxon";
  import DatePicker from "vue2-datepicker";
  import sortIcon from "@shared/assets/svg/dropdown-double.svg";
  import { format , parse } from "date-fns";
  import {FormatDateNew} from "@/plugins/functions";
  
  export default {
    name: "email-list",
    data: function () {
      return {
        columnsData: [
          {
            label: "Tenant Name",
            field: "tenant_name",
            // filterOptions: {
            //   enabled: true,
            // },
            config: {
              filter: true,
              alignCenter: true,
              field_type: 'action_button',
            },
          },
          {
            label: "Requested Domain",
            field: "requested_domain_name",
            // filterOptions: {
            //   enabled: true,
            // },          
            config: {
              filter: true,
              alignCenter: true,
              field_type: 'action_button',
            },
          },
          {
            label: "Status",
            field: "status",
            // filterOptions: {
            //   enabled: true,
            // },
            config: {
              filter: true,
              alignCenter: true,
              field_type: 'action_button',
            },
          },
          {
            label: "Requested At",
            field: "requested_at",
          },
        ],
        params: {
          page: 1,
          limit: 10,
        },
        totalRows: 0,
        filters: {},
        serverParams: {},
        searchLoading:{},
        date_range: [],
        filterRefs: [],  
        filterOptionsAsync: {}, 
        filterOptions: {}, 
        isSelectionChanged: false,
        rowsData: null,
        loadingTenants: true,
        editTenantDetails: false,
      };
    },
    components: {
      SubHeader,
      dropdown,
      sortIcon,
      DatePicker,
    },
    async mounted() {
      await this.getDomainTenants();
      this.filterOptions = this.columnFilterOptions
      this.loadingTenants = false;
    },
    computed: { 
    isFilters() {
              return this.serverParams && Object.entries(this.serverParams).length;
    },
    columnFilterOptions() {
      return this.getFilterOptions(this.columnOption, this.rowsData);
    },
    columnOption() {
      return this.columnsData.map((el) => ({
        ...el,
        config: {
                ...el.config,
                isLoading: !!this.searchLoading[el.field] || false,
                },
      }));
    },
    computedColumns() {
      // use option list from server if it is:
      const options = this.columnFilterOptions;
      // had to check for empty object
      if (options && Object.entries(options).length) {
        return this.columnsData.map((col) => {
          const filtered = col.config?.filter ?? true;
          if (col.prefetchedOptions && col.prefetchedOptions.length) {
            return {
              ...col,
              filterOptions: {
                enabled: filtered,
                filterDropdownItems: col.prefetchedOptions,
              },
            };
          } else {
            return {
              ...col,
              filterOptions: {
                enabled: filtered,
                filterDropdownItems: options[col.field],
              },
            };
          }
        });
      } else {
        // TODO remove/rework default filters
        return this.columnsData.map((col) => {
          const filtered = col.config?.filter ?? true;
          return {
            ...col,
            filterOptions: filtered
              ? {
                enabled: true,
                placeholder: "All",
                filterDropdownItems: this.rowsData
                  .filter((row) => row[col.field])
                  .map((row) => ({ id: row.field_type_id ?? uuid.v4(), name: row[col.field] }))
                  .filter(onlyUnique),
              }
              : undefined,
          };
        });
      }
    }
  },
    methods: {
      format,
      FormatDateNew,
      async getDomainTenants() {
        const filterParams = this.makeFilterParams();
        this.loadingTenants = true;
        let qParams
        qParams = new URLSearchParams(this.params);
        if (filterParams) {
          qParams = new URLSearchParams({ ...this.params, ...filterParams });
        }
        const { data } = await getEmailDomainTenantsList(qParams);
        this.rowsData = data.data;
        this.rowsData = this.rowsData?.map(el => {
          return {
            ...el,
            requested_at: typeof el.requested_at != 'string' ? FormatDateNew(new Date(el.requested_at)) : el.requested_at,
          }
        })
        console.log("rowsData", this.rowsData)
        this.totalRows = data?.pagination_info.total_count;
        this.loadingTenants = false;
      },
      makeFilterParams() {
                  let filterParams = {};
                  if (this.serverParams) {
                      Object.keys(this.serverParams).map((key) => {
                          filterParams[key] = this.serverParams[key]
                          ? this.serverParams[key]["name"]
                          : delete filterParams[key];
                          if (this.serverParams[key]["name"] === undefined) {
                              delete filterParams[key];
                          }
                          if (key.includes("date_from") || key.includes("date_to")) {
                              filterParams[key] = this.formatDate(filterParams[key]);
                          }
                      });
                  }
                  return filterParams;
              },
      async clearFilters() {
                  this.serverParams = null;
                  await this.getDomainTenants();
      },
      async onColumnFilter({ columnFilters }) {
                  this.params.page = 1;
                  this.serverParams = columnFilters ? { ...columnFilters } : null;
                  await this.getDomainTenants();
      },
      selectionChanged(e) {
              // console.log('e :>> ', e);
              if (e) {
                  this.isSelectionChanged = e.selectedRows && e.selectedRows.length !== 0;
                  this.$emit('on-selected-rows-change', e.selectedRows)
              }
          },
      getFilterOptions(columns, row) {
        return columns.reduce((result, curr) => {
          result[curr.field] = row
            .filter((row) => row[curr.field])
            .map((row) => row[curr.field])
            .filter(onlyUnique)
            .map((el) => ({ id: uuid.v4(), name: el }));
          return result;
        }, {});
      },
      updateColumnFilters(column, value /* , callback */) {
        let filterValObj = {
          ...this.serverParams,
          [column.query_key || column.field]: value ? {
            ...value,
            field_type: column.field_type,
          } : null,
        };
        if (!filterValObj[column.query_key || column.field]) {
          delete filterValObj[column.query_key || column.field];
        }
        if (column.query_key && ["from_due_date", "from_created_at"].includes(column.query_key)) {
          let date = DateTime.fromISO(value.name).toISODate()
          if (date == null) {
            const parsedDate = parse(value.name, 'd MMM yyyy', new Date());
            date = format(parsedDate, 'yyyy-MM-dd');
            if (filterValObj.from_created_at) {
              filterValObj.from_created_at.name = date.toString().substring(10, 0)
            } else {
              filterValObj.from_due_date.name = date.toString().substring(10, 0)
            }
          }
          Object.assign(filterValObj, {
            [`to_${column.field}`]: {
              id: date.ts,
              name: date.toString().substring(10, 0),
              field_type: column.field_type
            },
          });
        }
        if (column.config && /* column.config.query_keys && */ column.config.filter_type === 'date') { // uncommment when you want to add customkeys for backend filters
          let [start, end] = value.map(el => DateTime.fromJSDate(new Date(el)))
          Object.assign(filterValObj, {
            [column.config.query_keys['start']]: {
              id: start.ts,
              name: value[0] ? start.toString() : null,
              field_type: column.field_type
            },
          })
          Object.assign(filterValObj, {
            [column.config.query_keys['end']]: {
              id: end.ts,
              name: value[1] ? end.toString() : null,
              field_type: column.field_type
            },
          })
        }
        this.onColumnFilter( {
          columnFilters: filterValObj,
        });
      },
      async onPageChange(data, resetPage = false) {
        if (resetPage) {
          this.params.page = 1;
        } else this.params.page = data.currentPage;
        this.params.limit = data.currentPerPage;
        await this.getDomainTenants();
      }, 
      searchFilter(column, value) {
              this.$emit("search-filter", { column, value });
      },
      setFilterRef(el) {
              if (el) {
                  this.filterRefs.push(el);
              }
      },
      getFilterValue(col) {
              // isFilters ? filters[column.field] : null
              if (this.isFilters) {
                  return this.serverParams[col.field] ?? this.serverParams[col.query_key];
              } else return null;
          },
    },
  };
  </script>
  
  <style scoped lang="scss">
  @import "@shared/assets/mixins/mixins.scss";
  @include tableComponent;
  </style>
  