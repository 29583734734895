<template>
    <div class="">
        <div class="py-24 flex items-center justify-center" v-if="isLoading">
            <Loader />
        </div>
        <div v-else class="infoComponent" :class="{'infoComponentClient': windowWidth < 715}">
            <div class="HeadingPart bg-primary-300">Tenant Information</div>
            <FormulateForm class="bg-white p-6">
            <div class="grid grid-cols-2 gap-x-6 infoHeadings">
                    <FormulateInput class="w-full" label="Tenant name" placeholder="Tenant name" type="text" validation="required|matches:/^[a-zA-Z\s]+$/" :validation-messages="{ matches : 'Tenant name cannot contain number or any special characters'}" v-model="tenantInformation.title" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        <div class="grid gap-2">
                            <label class="infoHeadings">
                                    Domain Name
                                    <span class="requiredFileds">*</span>
                                    <div class="customDomainInput flex mt-2 min-w-full">
                                        <input type="text" v-model="tenantInformation.domain_name"  placeholder="Domain Name" class="input input-bordered"
                                        :class="$route.params.tenant_id ? 'disabledInput' : ''"
                                        :disabled="$route.params.tenant_id" @input="handleDomainName"
                                        />
                                        <span class="flex justify-center items-center px-4">.delvium.xyz</span>
                                    </div>
                                    <span v-if="showErrors.error_filed_name == 'domain_name' && showErrors.has_error" class="text-red-400 text-xs">{{ showErrors.msg }}</span>      
                                </label>  
                        </div>
                        <!-- <label>
                            <FormulateInput  class="" label="Domain Name" placeholder="Domain Name" type="text" validation="required" :disabled="$route.params.tenant_id" v-model="tenantInformation.domain_name" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <span>.delvium.com</span>
                        </label> -->
                    <div>
                        <label>Onboarding date <span class="text-red-500 text-lg">*</span></label>
                        <input class="w-full input input-bordered onboardingDate" label="Onboarding date" validation="required" placeholder="Onboarding date" type="text" v-model="tenantInformation.created_at"
                        Disabled/>
                    </div>
                        <FormulateInput  class="w-full" label="Account Number" validation="required" placeholder="Account Number" type="text" v-model="tenantInformation.account_number" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput class="w-full" label="Website" placeholder="Website" validation="required|matches:/^[a-zA-Z\s]+$/" :validation-messages="{ matches: 'Website cannot contain number or any special characters'}" type="url" v-model="tenantInformation.website" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <FormulateInput class="w-full" label="Registration number" validation="required" placeholder="Company registration number" type="text" v-model="tenantInformation.registration_number" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                    <FormulateInput class="w-full" label="Tenant client action alert emails" placeholder="Final report alert emails" type="pills" v-model="tenantInformation.final_report_alert_emails" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                        <FormulateInput class="w-full" label="Telephone" placeholder="Telephone" type="text" v-model="tenantInformation.telephone" :element-class=" (context, classes) => ['flex-1 min-w-full'].concat(classes)" @keypress="isIntegerNumber" />
                                        </div>
                <div class="grid grid-cols-2 gap-6 infoHeadings">
                    <FormulateInput type="image" name="Tenant logo" :uploader="uploadClientLogo" @file-removed="removeClientLogo()" :value="tenantInformation.logo_url" label="Tenant Logo" help="Select a png, jpg, jpeg or svg to upload." validation="mime:image/jpeg,image/png,image/svg+xml,image/jpeg" :maxSize="50" :validation-messages="{ mime: 'Client logo must be of type jpg/jpeg svg, png' }" />
                    <FormulateInput class="w-full" label="Tagline" placeholder="Tagline" type="text" v-model="tenantInformation.tagline" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                </div>

                <label class="infoHeadings">Powered By Delvium</label><br>
                
                <div class="flex mt-1">
                    <div class="grid grid-cols-2 powered-by">
                        <label class="text-center toggle-label-btn">Powered By</label>
                        <div>
                            <!-- <span class="text-white-text text-xs">Powered by</span> -->
                            <img :src="delviumLogo" class="flex-1 min-w-full" />
                        </div>
                    </div>
                    <div class="flex items-center justify-between ml-4">
                        <span class="toggle-label-btn pr-1">Disable</span>
                        <div class="toggle-btn-cnt">
                            <label class="switch">
                                <input type="checkbox" @click="handleToggle" :checked="tenantInformation.powered_by">
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <span class="toggle-label-btn">Enable</span>
                    </div>
                </div>
            </FormulateForm>
        </div>
        <div class="flex mt-5 p-4 pr-8 justify-end w-full bg-white nextButton">
            <Button class="relative bottom-0" :text="$route.params.tenant_id ? 'Update & Next':'Save & Next'" :loader="isFormSubmit" type="primary" @click="handleSubmit" />
        </div>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import axios from "@/axios";
import Loader from "@shared/loader";
import {getTenantDetails}  from "../../edit-tenant/services"
import { validDomainName, FormatDateNew } from "@/plugins/functions";
import { 
    // isNumber, 
    isIntegerNumber 
} from "@/plugins/functions";
import { delviumLogo } from "@/plugins/common"

export default {
    name: "tenantInformation",
    components: {
        Button,
        Loader
    },
    props: {
        loadingStates:{
            type:Object,
            default: () => {}
        }
    },
    data() {
        return {
            tenantInformation: {
                title: null,
                created_at: FormatDateNew(new Date()),
                account_number: null ,
                website: null,
                registration_number: null,
                final_report_alert_emails: [],
                client_action_alert_emails: [],
                telephone: null,
                logo_url: null,
                domain_name: null,
                email: null,
                key: "client_information",
                windowWidth: null,
                admin: {
                    email: null,
                    user_name: null
                }
            },
            clientId: null,
            isLoading: false,
            isShowError: false, 
            showErrors: {error_filed_name: '', has_error: false, msg: ''},
            delviumLogo,
        };
    },
    // beforeUnmount() {
    //     window.removeEventListener('resize', this.handleWindowResize);
    // },
    computed: {
        isFormSubmit(){
            return this.loadingStates.formSubmit
        },
        permissionsList(){
            return this.tenantInformation.permissions
        },
    },
    async mounted() {   
        if(this.$route.params?.tenant_id) {
            this.isLoading = true
            const {data} = await getTenantDetails(this.$route.params.tenant_id)
            let {title, 
            created_at, 
            account_number, website,
            registration_number, 
            final_report_alert_emails,
            client_action_alert_emails,
            telephone,
            logo_url,
            domain_name,
            email,powered_by,
            tagline}= data
            this.tenantInformation = {
            title,
            created_at: created_at ? FormatDateNew(new Date(created_at)) : '-', 
            account_number, website, 
            registration_number, 
            final_report_alert_emails,
            client_action_alert_emails,
            telephone,
            logo_url : logo_url && [{url:logo_url}] || null,
            domain_name,
            email,
            powered_by,
            tagline
            }
            this.tenantInformation["key"] = "client_information"
            this.$emit('enableAllSelection')
            this.isLoading = false
        }
        window.addEventListener('resize', this.handleWindowResize);
        this.getWindowSize();
        console.log("getWindowSize",this.windowWidth )
    },
    created()  {
        this.getWindowSize();
    window.addEventListener("resize", this.handleWindowResize);
    // console.log("update",this.windowWidth)
},

beforeMount()  {
    this.getWindowSize();
    window.removeEventListener("resize", this.handleWindowResize);
    // console.log("before",this.windowWidth)
},
    methods: {
        isIntegerNumber,
        // isNumber,
        validDomainName,
        FormatDateNew,
        checkError() {
            const { title, domain_name, account_number, registration_number, created_at } = this.tenantInformation
            if (!title || 
                !domain_name || 
                !account_number || 
                !registration_number ||
                !created_at
            ) {
                return true;
            } else return false;
        },
        handleDomainName(e) {
            if (this.isShowError && e.target.value && !validDomainName(e.target.value)) {
                this.showErrors = {error_filed_name: 'domain_name', has_error: true, msg: 'Invalid domain name!'}
            } else {
                this.showErrors = {error_filed_name: 'domain_name', has_error: false, msg: ''}
            }
        },
        handleSubmit() {
            this.isShowError = true;
            let hasError = this.checkError();
            if (hasError) {
                this.$toast.error(`Please fill all the required fields`);
            } else if (!validDomainName(this.tenantInformation.domain_name)) {
                this.$toast.error('Special character/Capital letters are not allowed in domain name!');
                this.showErrors = {error_filed_name: 'domain_name', has_error: true, msg: 'Invalid domain name!'}
                return;
            } else {
                this.$emit("submitForm", this.tenantInformation);
            }
        },
        handleWindowResize() {
             this.getWindowSize();
        },
        getWindowSize() {
            this.windowWidth = window.innerHeight;
        },
        async uploadClientLogo(file, progress) {
            // console.log(file, "file")
            if (file) {
                let allowedFiles = ["image/png", "image/svg+xml", "image/png", "image/jpeg"];
                if (!allowedFiles.includes(file.type)) return this.$toast.error("Only accept svg, png, jpg, jpeg");
            }
            if (file.size / 1e6 > 50) {
                this.tenantInformation.logo_url = null;
                return this.$toast.error("File size should be less than 50 MB");
            }
            progress(0);
            let url = `/client/logo`;
            let formData = new FormData();
            formData.append("file", file);
            try {
                let { data } = await axios.post(url, formData);
                this.tenantInformation = { ...this.tenantInformation, logo_url: [{ url: data?.data?.logo_url }] };
                progress(100);
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to upload logo");
                this.tenantInformation.logo_url = null;
                console.log(error, "error");
            }
            return Promise.resolve({});
        },

        async removeClientLogo() {
            if (!this.clientId) {
                let url = `/client/logo`;
                let payload = {
                    logo_url: this.tenantInformation?.logo_url[0]?.url,
                };
                await axios.delete(url, { data: payload });
            }
            this.tenantInformation = { ...this.tenantInformation, logo_url: null };
        },

        handleToggle(e){
            console.log("e",e);
            this.tenantInformation.powered_by = e.target.checked
            return this.tenantInformation.powered_by
        }
    },
};
</script>

<style lang="scss" scoped>
    .infoComponent {
        // height: 90vh;
        position: absolute;
        // z-index: 0;
    }
    .infoComponentClient {
        height: 125vh;
        position: relative !important;
        z-index: 0;
    }
    .HeadingPart {
        // background-color: #99CBFF;
        padding: 10px;
        border-radius: 10px 10px 0px 0px;
        color: #282828;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .onboardingDate {
        height: 37px;
        border: 1px solid var(--theme-color-box-stroke);
        border-radius: .3rem;
    }
    .customDomainInput {
        max-width: 20em;
        input {
            width: 100%;
            height: 32px;
            border-right: none;
            border-radius: .3rem 0 0 .3rem;
        }

        span {
            border: 1px solid #cecece;
            border-left: none;
            border-radius: 0px .3rem .3rem 0px;
            opacity: 0.8;
            background: #F2F2F2;
            color: #0D69D5;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
    .disabledInput {
        border: 1px solid #cecece;
    }
    .infoHeadings {
        color: #282828;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
    }

    .requiredFileds {
        color: #F00;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .nextButton {
        position: fixed;
        left: 0;
        bottom: 0;
    }
    .height{
        height: 53vh
    }
    .powered-by{
        display: flex;
        flex-direction: column;
        width: 115px;
        padding: 6px;
        border: 1px solid #bab2b2c9;
        border-radius: 7px;
    }
    .powered-by>div{
        width: 100px;
        height: 20px;
    }
    .switch {
        position: relative;
        display: inline-block;
        // width: 60px;
        // height: 34px;
    }

    .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        width: 44px;
        height: 21px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 17px;
        width: 17px;
        left: 5px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .toggle-btn-cnt{
        display: flex;
        align-items: center;
        width: 49px;
    }
    .toggle-label-btn{
        font-size: 13px !important;
    }
</style>
